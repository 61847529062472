import { currentAppBrand } from '@b2ag/app-brands'
import { getAuthConfigFor, provideAuthService, useAuthService } from '@b2ag/auth'
import { createAxiosClientAnonymous, createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { providePartner } from '@b2ag/composables/src/partner/usepartner'
import { provideFeatureFlipping, useFeatureFlipping } from '@b2ag/feature-flipping'
import i18n from '@b2ag/locale/src/plugins/i18n.plugin'
import { loadLocaleFor } from '@b2ag/locale/src/plugins/loadLocalFor'
import type { GtmSupportOptions } from '@b2ag/plugin-aladin-gtm'
import { initAnalyticsIdf, useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import { initSentry } from '@b2ag/sentry'
import '@b2ag/utils/src/indexedDB'
import Vue from 'vue'
import { setLoggerLevel } from '@b2ag/composables/src/useLogger'
import { getConfig } from '@b2ag/micro-front-tools'
import AxeptioPlugin from '@b2ag/plugin-axeptio'
import { initServicesForStore } from '@b2ag/store'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import AsyncComputed from 'vue-async-computed'
import InstantSearch from 'vue-instantsearch'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import VueObserveVisibility from 'vue-observe-visibility'
import HubspotPlugin from '@b2ag/plugin-hubspot'
import App from './App.vue'
import router from './router'
import {
  discountService,
  membershipApiClient,
  offerService,
  recommendationService,
  searchService,
  stockService,
  storeService,
} from './services'
import store from './store/store'

loadLocaleFor(currentAppBrand)

const contextApp = getConfig('CONTEXT_APP')

if (!Array.prototype.flatMap) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'flatMap', {
    value(f) {
      return this.map(f).reduce((acc, val) => acc.concat(val), [])
    },
  })
}

Vue.config.productionTip = false

Vue.use(i18n)
Vue.use(VueObserveVisibility)
Vue.use(InstantSearch)
Vue.use(AsyncComputed)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  lazyComponent: true,
  observer: true,
})

// Global include all components from the design system
const requireComponent = require.context('@b2ag/design-system/src/components/', true, /Adn[A-Z]\w+\.vue$/)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^.*\//, '').replace(/\.vue$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})

function getGtmSupportOptions(): GtmSupportOptions {
  const ids: string[] = []

  if (contextApp === 'tech' && window.env?.GTM_ID) ids.push(window.env.GTM_ID)
  if (contextApp === 'agri' && window.env?.GTM_ID_B2AG) ids.push(window.env.GTM_ID_B2AG)
  if (contextApp === 'agri' && window.env?.GTM_ID_LEGACY) ids.push(window.env.GTM_ID_LEGACY)
  if (contextApp === 'agri' && window.env?.GTM_ID_SPECIFIC) ids.push(window.env.GTM_ID_SPECIFIC)

  return {
    id: ids,
    enabled: true,
    defer: true,
    debug: window.env?.ENVIRONMENT_NAME === 'DEV',
    loadScript: true,
  }
}

function initAxeptioAndGTM() {
  if (window.location.hostname.includes('localhost')) return

  if (window.env?.AXEPTIO_CLIENT_ID) {
    Vue.use(AxeptioPlugin, {
      clientId: window.env?.AXEPTIO_CLIENT_ID,
      base: window.env?.AXEPTIO_BASE,
      domain: window.env?.AXEPTIO_DOMAIN,
      flushStoredEvents: () => useAnalyticService()?.flushStoredEvents(),
    })

    initAnalyticsIdf(getGtmSupportOptions(), useAuthService)
  }
}

async function mountApp() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  } as any).$mount('#app')
}

function initHubspot() {
  if (!window.env?.HUBSPOT_PORTAL_ID || window.location.hostname.includes('localhost')) return
  Vue.use(HubspotPlugin, {
    portalId: window.env.HUBSPOT_PORTAL_ID,
  })
}

function initPartners() {
  const izanamiApiClient = createAxiosClientAnonymous(`${window.env.FEATURE_FLIPPING_API_HOST}`)
  const userApiHost = getConfig('USER_API_HOST')
  const partnerApiClient = createAxiosClientWithAladinBearer(`${userApiHost}/v1/cooperatives`, {
    callerId: buildCallerId(),
  })
  providePartner(izanamiApiClient, partnerApiClient, currentAppBrand)
}

function updateFeatureFlipping(coopId: number) {
  return useFeatureFlipping().updateFeatureFlipping(coopId)
}

async function initAuthService() {
  await provideAuthService({
    audience: window.env.AUTH0_AUDIENCE!,
    domain: window.env.AUTH0_DOMAIN!,
    redirectUri: `${window.env.FRONT_URL}/oauth/callback`,
    authority: window.env.AUTH0_DOMAIN!,
    clientId: window.env.AUTH0_CLIENT_ID!,
    requiredScopes: getAuthConfigFor(contextApp),
    extranetConnection: window.env.AUTH0_EXTRANET_CONNECTION,
  })
}

function initFeatureFlipping() {
  const featureFlippingApiClient = createAxiosClientAnonymous(`${window.env.FEATURE_FLIPPING_API_HOST}`)

  provideFeatureFlipping(featureFlippingApiClient, contextApp, currentAppBrand)
}

setLoggerLevel(window.env.LOGGER_LEVEL)
;(async (): Promise<void> => {
  try {
    initSentry({
      router,
      params: {
        Vue,
        dsn: window.env.SENTRY_DSN,
        enabled: window.env.SENTRY_ENABLED === 'yes',
        environment: window.env.ENVIRONMENT_NAME,
        release: window.env.VERSION,
        tracesSampleRate: window.env.SENTRY_TRACE_SAMPLE_RATE,
        allowUrls: window.env.SENTRY_ALLOW_URLS,
      },
    })

    await initAuthService()
    initAxeptioAndGTM()
    initFeatureFlipping()
    initHubspot()
    initPartners()

    initServicesForStore({
      membershipApiClient,
      searchService,
      stockService,
      recommendationService,
      storeService,
      discountService,
      offerService,
    })
    // eslint-disable-next-line import/no-named-as-default-member
    await updateFeatureFlipping(store.getters.currentCooperativeId)
    await mountApp()
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
})()
