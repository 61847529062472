import { createMembershipApiClient } from '@aladin/shared-universcoop-sdk'
import { AlgoliaSearchService } from '@b2ag/search'
import OfferService from '@b2ag/offers/src/services/offer.service'
import algoliasearch from 'algoliasearch/lite'
import { AuthLocationStorage, useAuthService } from '@b2ag/auth/dist/esm'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { StockService } from '@b2ag/stock'
import { DiscountService } from '@b2ag/discount'
import { StoreService } from '@b2ag/coop-store'
import ProductService from '@b2ag/product/src/services/product.service'
import RecommendationService from '@b2ag/product/src/services/recommendation.service'

import { buildCallerId } from '@b2ag/utils/src/caller-id'
import CartService from '@b2ag/cart/src/cart.service'
import LineItemService from '@b2ag/cart/src/line-item.service'
import CooperativeService from './cooperative.service'
import MembershipConfirmationService from './membership-confirmation.service'
import UserService from './user.service'
import ProfileService from './profile.service'
import IdfTrackingService from './idf-tracking.service'

window.env = window.env || {}

const orderApiHost = window.env.ORDER_API_HOST
const cartApiHost = window.env.CART_API_HOST
const productApiHost = window.env.PRODUCT_API_HOST
const discountApiHost = window.env.DISCOUNT_API_HOST
const userApiHost = window.env.USER_API_HOST
const searchAppId = window.env.ALGOLIA_APP_ID
const searchApiKey = window.env.ALGOLIA_API_KEY
const idfTrackingApiHost = window.env.IDF_TRACKING_API_HOST!
const algoliaClient = algoliasearch(searchAppId, searchApiKey)

const accessTokenSource = () => useAuthService().getAccessToken()

const searchService = new AlgoliaSearchService(algoliaClient)
const cooperativeService = new CooperativeService(userApiHost)
const cartService = new CartService({ cartApiHost })
const membershipConfirmationService = new MembershipConfirmationService(userApiHost)
const lineItemService = new LineItemService({ cartApiHost })
const offerService = new OfferService(createAxiosClientWithAladinBearer(productApiHost, { callerId: buildCallerId() }))
const profileService = new ProfileService(userApiHost)
const productService = new ProductService(productApiHost)
const discountService = new DiscountService({ discountApiHost })
const recommendationService = new RecommendationService(productApiHost)
const userService = new UserService(userApiHost)
const stockService = new StockService(orderApiHost)
const storeService = new StoreService({ userApiHost })

const membershipApiClient = createMembershipApiClient({
  membershipApiHost: userApiHost,
  accessTokenSource,
})
const idfTrackingService = new IdfTrackingService(idfTrackingApiHost)
const locationStorage = new AuthLocationStorage(localStorage)

export {
  searchService,
  cartService,
  cooperativeService,
  offerService,
  profileService,
  lineItemService,
  membershipConfirmationService,
  productService,
  discountService,
  userService,
  recommendationService,
  stockService,
  storeService,
  membershipApiClient,
  idfTrackingService,
  algoliaClient,
  locationStorage,
}
